import React, { useState, useEffect } from "react";
import Button from "./Button";
import { X } from "heroicons-react";
import { getUserCategories, updateArticle } from "../services/request";
import Loader from "./Loader/Loader";
import Loader2 from "./Loader/Loader2";

const MoveArticle = ({
  setIsOpenModal,
  article,
  refresh,
  setRefresh,
  category,
}) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");

  const getCategories = async () => {    
    let alpsToken = localStorage.getItem("alpsToken");
    let data;    
    if (alpsToken) {      
      data = await getUserCategories(alpsToken);
      if (data) {
        setCategories(data);
      }      
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let alpsToken = localStorage.getItem("alpsToken");
    if (selected) {
      setLoading(true);
      let data = await updateArticle(
        alpsToken,
        {
          category: selected.id,
        },
        article.id
      );
      setLoading(false);   
      setRefresh(!refresh);
      setIsOpenModal(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div
        className="fixed z-50 top-0 right-0 w-full h-[100vh] flexmm bg-secondary3 font-inter cursor-default"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        <div
          className="flexmm w-[483px] flex-shrink z-[1000] rounded-[6px] mt-[30px] p-[20px] bg-white shadow-xl"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form className="cflexss w-full gap-[32px]" onSubmit={handleSubmit}>
            <div className="w-full flexbm text-[18px] font-semibold text-buttonColor">
              <p>
                Move <span className="text-[#7C7D7E]">{article.title}</span> to
                a new category
              </p>
              <X
                size="20px"
                color="#7C7D7E"
                className="cursor-pointer"
                onClick={() => {
                  setIsOpenModal(false);
                }}
              />
            </div>
            <div className="cflexss w-full gap-[8px]">
              <p className="text-[14px] text-[#656667] font-semibold">
                Select category
              </p>
              <div className="w-full cflexss overflow-y-auto max-h-[50vh]">
                {categories.length > 0 ? (
                  <>
                    {categories.map((item, index) => {
                      return (
                        <>
                          {item.name !== category.name && (
                            <>
                              <div
                                className="w-full text-[16px] font-normal flexsm py-[16px] border-b-[2px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                }}
                              >
                                <div className="flexsm gap-[16px]">
                                  <input
                                    type="radio"
                                    checked={item.name === selected.name}
                                  />
                                  <p>{item.name}</p>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="w-full h-full cflexmm gap-[20px]">
                      <p>Fetching Categories...</p>
                      <Loader2 />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-full flexem">
              <Button type="submit" handleClick={() => {}}>
                {loading ? (
                  <div className="flexmm gap-[10px]">
                    <Loader />
                    <p>Move article</p>
                  </div>
                ) : (
                  <p>Move article</p>
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MoveArticle;
