import React, { useState, useEffect } from "react";
import NavBar from "../collections/NavBar";
import { CheckCircleOutline } from "heroicons-react";
import Button from "../../components/Button";
import Loader from "../../components/Loader/Loader";
import Loader2 from "../../components/Loader/Loader2";
import { getFormData, updateResolved } from "../../services/request";

const FormsContent = () => {
  const [bugs, setBugs] = useState([]);
  const [features, setFeatures] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [view, setView] = useState("");
  const [form, setForm] = useState("Bugs");
  const [formType, setFormType] = useState("Bugs");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const formatDate = (dateStr) => {
    if (dateStr) {
      const date = new Date(dateStr);

      const formattedDate = date.toLocaleDateString("en-US", {
        month: "long", // Full month name
        day: "numeric", // Day of the month
        year: "numeric", // Full year
      });

      return formattedDate;
    } else {
      return "";
    }
  };

  const getForm = async (formType, id) => {
    const alpsToken = localStorage.getItem("alpsToken");
    let data = await getFormData(alpsToken);
    setBugs(data?.Bugs?.length > 0 ? data?.Bugs : []);
    setFeatures(data?.Features?.length > 0 ? data?.Features : []);
    setQuestions(data?.Questions?.length > 0 ? data?.Questions : []);
    if (!formType) {
      setView(data?.Bugs?.length > 0 ? data?.Bugs[0] : "");
      console.log(data);
    } else if (formType === "Bugs") {
      bugs.findIndex((bug, index) => {
        if (bug._id === id) {
          setView(data?.Bugs?.length > 0 && data?.Bugs[index]);
        }
      });
    } else if (formType === "Features") {
      features.findIndex((feature, index) => {
        if (feature._id === id) {
          setView(data?.Features?.length > 0 && data?.Features[index]);
        }
      });
    } else if (formType === "Questions") {
      questions.findIndex((question, index) => {
        if (question._id === id) {
          setView(data?.Questions?.length > 0 && data?.Questions[index]);
        }
      });
    }
  };

  const setResolved = async (id, status) => {
    const alpsToken = localStorage.getItem("alpsToken");
    setLoading(true);
    await updateResolved({ id, status }, alpsToken);
    getForm(formType, id);
    setLoading(false);
  };

  const filteredBugs = bugs?.filter(
    (bug) =>
      bug.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      bug.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredFeatures = features?.filter(
    (feature) =>
      feature.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feature.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredQuestions = questions?.filter(
    (question) =>
      question.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      question.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken) {
      getForm();
    } else {
      window.location.href = "/login";
    }
  }, []);
  return (
    <>
      <NavBar active="Forms" />
      <div className={`w-full flexss h-full font-inter mt-[95px]`}>
        <div
          className="w-[350px] cflexss pb-[40px] border-r-[1px] text-primary1 flex-shrink overflow-y-auto"
          style={{ height: "calc(100vh - 95px)" }}
        >
          <div className="w-full px-[22px] py-[14px] cflexmm gap-[10px] border-b-[2px]">
            <form
              className="w-full rounded-[15px] px-[12px] py-[8px] flexsm gap-[12px] border-[1px]"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <img
                src="/search-icon.svg"
                alt="search-icon"
                className="w-[20px] h-[20px] cursor-pointer"
              />
              <input
                name="searchQuery"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Search anything"
                className="outline-none w-full"
              />
            </form>
            <div className="w-full flexbm rounded-[10px] text-[#8C8C8C] bg-[#FAFAFA] px-[5px] py-[9px]">
              <div
                className={`flexmm rounded-[10px] px-[7px] py-[4px] gap-[4px] font-medium text-[14px] cursor-pointer ${
                  formType === "Bugs" && "bg-white text-[#000000]"
                } transition-all duration-300`}
                onClick={() => {
                  setFormType("Bugs");
                }}
              >
                <p>Bugs</p>
                <div className="bg-[#1072E5] w-[15px] h-[15px] flexmm rounded-full text-white font-medium text-[10px]">
                  {bugs.length}
                </div>
              </div>

              <div
                className={`flexmm rounded-[10px] px-[7px] py-[4px] gap-[4px] font-medium text-[14px] cursor-pointer ${
                  formType === "Features" && "bg-white text-[#000000]"
                } transition-all duration-300`}
                onClick={() => {
                  setFormType("Features");
                }}
              >
                <p>Features</p>
                <div className="bg-[#1072E5] w-[15px] h-[15px] flexmm rounded-full text-white font-medium text-[10px]">
                  {features.length}
                </div>
              </div>

              <div
                className={`flexmm rounded-[10px] px-[7px] py-[4px] gap-[4px] font-medium text-[14px] cursor-pointer ${
                  formType === "Questions" && "bg-white text-[#000000]"
                } transition-all duration-300`}
                onClick={() => {
                  setFormType("Questions");
                }}
              >
                <p>Questions</p>
                <div className="bg-[#1072E5] w-[15px] h-[15px] flexmm rounded-full text-white font-medium text-[10px]">
                  {questions.length}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full cflexsm">
            {formType === "Bugs" &&
              filteredBugs.map((bug, index) => {
                return (
                  <>
                    <div
                      className="w-full flexbm px-[22px] py-[14px] gap-[9px] border-b-[1px] cursor-pointer hover:bg-[#F5F5F5] transition-all duration-300"
                      key={index}
                      onClick={() => {
                        setView(bug);
                        setForm("Bugs");
                      }}
                    >
                      <div className="cflexss text-[14px] font-medium text-black">
                        <p className="capitalize">{bug.subject}</p>
                        <p className="text-[12px] font-normal truncate w-[190px] whitespace-nowrap overflow-hidden">
                          {bug.message}
                        </p>
                      </div>
                      <div className="cflexse text-[12px] gap-[5px]">
                        <p>{formatDate(bug.createdAt)}</p>
                        {!bug?.isResolved && (
                          <div className="flexmm px-[3px] py-[4px]  text-[#1072E5] rounded-[3px] bg-[#1072E5]/10">
                            <p>Unresolved</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

            {formType === "Features" &&
              filteredFeatures.map((feature, index) => {
                return (
                  <>
                    <div
                      className="w-full flexbm px-[22px] py-[14px] gap-[9px] border-b-[1px] cursor-pointer hover:bg-[#F5F5F5] transition-all duration-300"
                      key={index}
                      onClick={() => {
                        setView(feature);
                        setForm("Features");
                      }}
                    >
                      <div className="cflexss text-[14px] font-medium text-black">
                        <p className="capitalize">{feature.subject}</p>
                        <p className="text-[12px] font-normal truncate w-[190px] whitespace-nowrap overflow-hidden">
                          {feature.message}
                        </p>
                      </div>
                      <div className="cflexse text-[12px] gap-[5px]">
                        <p>{formatDate(feature.createdAt)}</p>
                        {!feature?.isResolved && (
                          <div className="flexmm px-[3px] py-[4px]  text-[#1072E5] rounded-[3px] bg-[#1072E5]/10">
                            <p>Unresolved</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

            {formType === "Questions" &&
              filteredQuestions.map((question, index) => {
                return (
                  <>
                    <div
                      className="w-full flexbm px-[22px] py-[14px] gap-[9px] border-b-[1px] cursor-pointer hover:bg-[#F5F5F5] transition-all duration-300"
                      key={index}
                      onClick={() => {
                        setView(question);
                        setForm("Questions");
                      }}
                    >
                      <div className="cflexss text-[14px] font-medium text-black">
                        <p className="capitalize">{question.subject}</p>
                        <p className="text-[12px] font-normal truncate w-[190px] whitespace-nowrap overflow-hidden">
                          {question.message}
                        </p>
                      </div>
                      <div className="cflexse text-[12px] gap-[5px]">
                        <p>{formatDate(question.createdAt)}</p>
                        {!question?.isResolved && (
                          <div className="flexmm px-[3px] py-[4px]  text-[#1072E5] rounded-[3px] bg-[#1072E5]/10">
                            <p>Unresolved</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        {view ? (
          <div
            style={{
              height: "calc(100vh - 95px)",
              width: "calc(100vw - 330px)",
            }}
          >
            <div className="w-full cflexss gap-[10px] px-[124px] py-[38px] text-[#36383A]">
              <div className="w-full cflexss gap-[15px]">
                <div className="flexbm w-full">
                  <div className="flexsm gap-[8px] text-[16px]">
                    <div className="font-medium flexmm bg-[#9747FF] text-white px-[13px] py-[6px]">
                      <p>{form}</p>
                    </div>
                    <p className="font-normal">{formatDate(view?.createdAt)}</p>
                  </div>
                  <div className="flexem gap-[16px] text-[14px]">
                    <button
                      className={`relative px-[12px] py-[6px] flexmm gap-[8px] ${
                        view?.isResolved
                          ? "bg-[#1072E5] text-white group"
                          : "bg-[#F5F5F5]"
                      } rounded-[10px] transition-all duration-150`}
                      onClick={() => {
                        view?.isResolved
                          ? setResolved(view?._id, false)
                          : setResolved(view?._id, true);
                      }}
                    >
                      <div className="absolute z-[500] top-[-50px] left-[-50px] hidden group-hover:block">
                        <div className="bg-white text-black px-[10px] py-[10px] rounded-[10px] shadow-md text-nowrap">
                          Set to <b>Unresolved</b>
                        </div>
                      </div>
                      {loading ? (
                        view?.isResolved ? (
                          <Loader size="25px" />
                        ) : (
                          <Loader2 size="25px" />
                        )
                      ) : (
                        <CheckCircleOutline size="20px" />
                      )}
                      {view?.isResolved ? (
                        <p>Resolved</p>
                      ) : (
                        <p>Mark as resolved</p>
                      )}
                    </button>
                    <Button
                      paddingX="12px"
                      paddingY="6px"
                      gap="12px"
                      rounded="10px"
                      handleClick={() => {
                        window.location.href = `mailto:${view?.email}`;
                      }}
                    >
                      <p>Respond via email</p>
                    </Button>
                  </div>
                </div>
                <p className="text-[32px] font-medium text-[#36383A] capitalize">
                  {view?.subject}
                </p>
              </div>
              <div className="cflexss gap-[49px]">
                <div className="cflexss gap-[11px] text-[16px] font-normal">
                  <p className="text-[18px] font-semibold">Message</p>
                  <div className="cflexss">
                    <p>{view?.message}</p>
                  </div>
                </div>
                <div className="clfexss gap-[11px] text-[16px] font-normal">
                  <p className="text-[18px] font-semibold">Attachment(s)</p>
                  <p className="text-[#1072E5] cursor-pointer">
                    Payment Receipt.pdf
                  </p>
                  <p className="text-[#1072E5] cursor-pointer">
                    Payment Receipt.pdf
                  </p>
                  <p className="text-[#1072E5] cursor-pointer">
                    Payment Receipt.pdf
                  </p>
                  <p className="text-[#1072E5] cursor-pointer">
                    Payment Receipt.pdf
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="w-[80%] h-[90vh] cflexmm gap-[25px]"
            style={{ height: "calc(100vh - 190px)" }}
          >
            <div className="cflexmm text-[16px] font-[400] text-[#2C2B2B]">
              <img
                className="w-[170px] h-[170px]"
                src="/category-box.svg"
                alt="box"
              />
              <p>You have no forms on bugs</p>
            </div>{" "}
          </div>
        )}
      </div>
    </>
  );
};

export default FormsContent;
