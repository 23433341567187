import React, { useState, useEffect } from "react";
import NavBar from "./collections/NavBar";
import { getUserProfile } from "../services/request";
import Loader from "../components/Loader/Loader";
import { updateUserDesign } from "../services/request";
import { useNavigate } from "react-router-dom";

const SelectTheme = () => {
  const navigate = useNavigate();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [modal, setModal] = useState(null);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long", // Full month name
      day: "numeric", // Day of the month
      year: "numeric", // Full year
    });

    return formattedDate;
  };

  const AvailableThemes = [
    {
      id: 1,
      image: "/defaultTheme.svg",
      live: userProfile?.profile?.themeType === 1 ? true : false,
    },
    {
      id: 2,
      image: "/Theme1.svg",
      live: userProfile?.profile?.themeType === 2 ? true : false,
    },
    {
      id: 3,
      image: "/Theme2.svg",
      live: userProfile?.profile?.themeType === 3 ? true : false,
    },
  ];

  const updateThemeType = async (value, selected) => {
    console.log("setting theme type");
    if (selected === 1 && userProfile.profile) {
      setLoading1(true);
      await updateUserDesign(
        {
          themeType: value,
        },
        "themeType",
        setModal,
        setPopUp
      );
      setLoading1(false);
    } else if (selected === 2 && userProfile.profile) {
      setLoading2(true);
      await updateUserDesign(
        {
          themeType: value,
        },
        "themeType",
        setModal,
        setPopUp
      );
      setLoading2(false);
    } else if (selected === 3 && userProfile.profile) {
      setLoading3(true);
      await updateUserDesign(
        {
          themeType: value,
        },
        "themeType",
        setModal,
        setPopUp
      );
      setLoading3(false);
    }
    navigate("/design");
  };

  const getProfile = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      navigate("/login");
    } else {
      let info = await getUserProfile(alpsToken, setModal, setPopUp);
      setUserProfile(info);
      console.log(info);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <NavBar active="SelectTheme" />
      {popUp && modal}
      <div
        className={`w-full cflexss h-full font-inter px-[56px] py-[20px] gap-[32px] mt-[95px] pb-[100px]`}
      >
        <div className="cflexss gap-[6px] text-[16px] font-normal text-[#4D4E51]">
          <h1 className="font-medium text-[28px] text-[#000000]">
            Design Settings
          </h1>
          <p>
            Start with selecting a theme below then proceed to customise your
            help center with preferred colour and font
          </p>
        </div>
        <div className="cflexss gap-[16px] font-semibold text-[18px]">
          <p className="text-[#36383A]">Live Theme</p>
          {AvailableThemes.map((theme) => {
            return (
              theme.live && (
                <div
                  className="relative flexmm w-[690px] h-[331px] border-[1px] border-[#E6E6E6] rounded-[8px]"
                  key={theme.id}
                >
                  <img src={theme.image} className="w-[100%] h-[100%]" />
                  <div className="absolute bottom-[-5px] text-[16px] font-smibold left-0 w-full p-[16px] flexbm gap-[10px] bg-[#FFFFFF] rounded-b-[8px] border-b-[1px] border-[#E6E6E6]">
                    <div className={`cflexss gap-[6px]`}>
                      <div className="flexmm gap-[8px] text-[#4D4E51]">
                        <div className="bg-[#22C55E] w-[8px]  h-[8px] rounded-full" />
                        <div>Live</div>
                      </div>
                      <p className="text-[#A3A3A3] text-[14px] font-normal">
                        Updated {formatDate(userProfile?.profile?.updatedAt)}
                      </p>
                    </div>
                    <button
                      className="px-[62px] py-[8px] flexmm rounded-[8px] border-none outline-none text-white bg-[#2C2B2B] shadow-md"
                      onClick={() => {
                        updateThemeType(theme.id, theme.id);
                      }}
                    >
                      {theme.id === 1 &&
                        (loading1 ? (
                          <div className="flexmm gap-[10px]">
                            <Loader />{" "}
                            <p>
                              {userProfile?.profile?.themeType === 0
                                ? "Select theme"
                                : "Customize theme"}
                            </p>
                          </div>
                        ) : userProfile?.profile?.themeType === 0 ? (
                          "Select theme"
                        ) : (
                          "Customize theme"
                        ))}
                      {theme.id === 2 &&
                        (loading2 ? (
                          <div className="flexmm gap-[10px]">
                            <Loader />{" "}
                            <p>
                              {userProfile?.profile?.themeType === 0
                                ? "Select theme"
                                : "Customize theme"}
                            </p>
                          </div>
                        ) : userProfile?.profile?.themeType === 0 ? (
                          "Select theme"
                        ) : (
                          "Customize theme"
                        ))}
                      {theme.id === 3 &&
                        (loading3 ? (
                          <div className="flexmm gap-[10px]">
                            <Loader />{" "}
                            <p>
                              {userProfile?.profile?.themeType === 0
                                ? "Select theme"
                                : "Customize theme"}
                            </p>
                          </div>
                        ) : userProfile?.profile?.themeType === 0 ? (
                          "Select theme"
                        ) : (
                          "Customize theme"
                        ))}
                    </button>
                  </div>
                </div>
              )
            );
          })}
        </div>
        <hr width="100%" className="mt-[10px]" />
        <div className="cflexss gap-[16px] font-semibold text-[18px]">
          <p className="text-[#36383A]">Available Themes</p>
          <div className="flexsm gap-[32px]">
            {AvailableThemes.map((theme) => {
              return (
                <>
                  {!theme.live && (
                    <div className="relative flexmm w-[426px] h-[331px] border-[1px] border-[#E6E6E6] rounded-[8px]">
                      <img src={theme.image} className="w-[100%] h-[100%]" />
                      <div className="absolute bottom-[-5px] text-[16px] font-smibold left-0 w-full p-[16px] flexem gap-[10px] bg-[#FFFFFF] rounded-b-[8px] border-b-[1px] border-[#E6E6E6]">
                        <button
                          className="px-[17px] py-[8px] flexmm rounded-[8px] border-none outline-none text-white bg-[#2C2B2B] shadow-md"
                          onClick={() => {
                            updateThemeType(theme.id, theme.id);
                          }}
                        >
                          {theme.id === 1 &&
                            (loading1 ? (
                              <div className="flexmm gap-[10px]">
                                <Loader /> <p>Select theme</p>
                              </div>
                            ) : (
                              "Select theme"
                            ))}
                          {theme.id === 2 &&
                            (loading2 ? (
                              <div className="flexmm gap-[10px]">
                                <Loader /> <p>Select theme</p>
                              </div>
                            ) : (
                              "Select theme"
                            ))}
                          {theme.id === 3 &&
                            (loading3 ? (
                              <div className="flexmm gap-[10px]">
                                <Loader /> <p>Select theme</p>
                              </div>
                            ) : (
                              "Select theme"
                            ))}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTheme;
